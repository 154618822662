import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form' // redux-form reducer 
import { reducer as toastrReducer } from 'react-redux-toastr'

import login from './login';
import changeState from './changeState';
import searchState from './searchState';
import user from './user';
import cms from './cms';
import serviceableArea from './serviceableArea';
import vehicle from './vehicle';
import driver from './driver';
import faq from './faq';
import feedback from './feedback';
import product_category from './product_category';
import product_type from './product_type';
import product from './product';
import promoCode from './promoCode';
import subAdmin from './subAdmin';
import customer from './customer';
import order from './order';
import reports from './reports';
import cart from './cart'

const rootReducer = combineReducers({
    login,
    changeState,
    searchState,
    user,
    cms,
    serviceableArea,
    productType: product_type,
    productCategory: product_category,
    product: product,
    vehicle,
    driver,
    promoCode,
    faq,
    feedback,
    subAdmin,
    customer,
    order,
    reports,
    cart,
    toastr: toastrReducer,
    form: formReducer
})

export default rootReducer;