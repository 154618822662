
import { API_DELETE_NOTIFICATION, API_LOGOUT, API_NOTIFICATION_COUNT, API_NOTIFICATION_LIST, API_READ_NOTIFICATION } from "src/constants/api";
import { LOGIN_ERROR, LOGIN_SUCCESS, AUTHENTICATE_USER, AUTHENTICATE_FAILED, GET_PROFILE_SUCCESS, GET_PROFILE_ERROR, GET_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_ERROR, GET_NOTIFICATION_COUNT_SUCCESS, GET_NOTIFICATION_COUNT_ERROR, LOGOUT } from "src/constants/types";
import {
    API,
} from "../constants/types";

export const login = (data) => ({
    type: API,
    payload: {
        url: 'admin/login',
        method: 'POST',
        data: data,
        success: (data) => ({
            type: LOGIN_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: LOGIN_ERROR,
            payload: data
        })
    }
})

//change super admin pass
export const changePasswordSuperAdmin = (data) => ({
    type: API,
    payload: {
        url: 'api/admin/changePassword',
        method: 'PUT',
        data: data,
    }
})

//reset admin pass
export const resetPassword = (data) => ({
    type: API,
    payload: {
        url: 'admin/resetPassword',
        method: 'POST',
        data: data,
    }
})

// To logout
export const doLogout = (data) => ({
    type: API,
    payload: {
        url: API_LOGOUT,
        method: 'POST',
        hideLoader: true,
        data: data,
        success: (data) => ({
            type: LOGOUT,
        }),
        error: (data) => ({
            payload: data
        })
    }
})

//To reAuthenticate if the cookie is not expired
export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: AUTHENTICATE_FAILED })
    }
}

//forgot password
export const forgotPassword = (data) => ({
    type: API,
    payload: {
        url: 'admin/forgotPassword',
        method: 'POST',
        data: data,
    }
})

//get profile
export const getProfile = (data) => ({
    type: API,
    payload: {
        url: 'api/admin/getProfile',
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_PROFILE_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_PROFILE_ERROR,
            payload: data
        })
    }
})

//edit profile
export const editProfile = (data) => ({
    type: API,
    payload: {
        url: 'api/admin/editProfile',
        method: 'PUT',
        data: data,
    }
})


// NOTIFICATIONS

// Get All Notification
export const getNotificationList = (data) => ({
    type: API,
    payload: {
        url: API_NOTIFICATION_LIST,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_NOTIFICATION_LIST_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_NOTIFICATION_LIST_ERROR,
            payload: data
        })
    }
})

// Get All Notification
export const getNotificationCount = (data) => ({
    type: API,
    payload: {
        url: API_NOTIFICATION_COUNT,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_NOTIFICATION_COUNT_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_NOTIFICATION_COUNT_ERROR,
            payload: data
        })
    }
})

// To Read Notification
export const toReadNotification = (data) => ({
    type: API,
    payload: {
        url: API_READ_NOTIFICATION,
        method: 'POST',
        data: data,
    }
})

// To Delete Notification
export const toDeleteNotification = (data) => ({
    type: API,
    payload: {
        url: API_DELETE_NOTIFICATION,
        method: 'DELETE',
        data: data,
    }
})