import axios from 'axios';
import * as ACTION_TYPES from '../constants/types';

const initialState = {
    isLoggedIn: false,
    userData: {},
    profile: {},

    // Notification
    notificationList: [],
    notificationCount: 0,

    message: '',
    error: null
}

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_SUCCESS:
            axios.defaults.headers.common['Authorization'] = action.payload.data.token;
            localStorage.setItem('authTokenClefill', JSON.stringify(action.payload.data.token));
            localStorage.setItem('userClefill', JSON.stringify(action.payload.data));
            return {
                ...state,
                userData: action.payload.data,
                message: action.payload.message,
                isLoggedIn: true,
                error: null
            };
        case ACTION_TYPES.LOGIN_ERROR:
            return {
                ...state,
                error: action.payload.data
            };
        case ACTION_TYPES.AUTHENTICATE_USER:
            return {
                ...state,
                isLoggedIn: true,
                userData: action.payload,
                error: null
            };
        case ACTION_TYPES.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PROFILE_ERROR:
            return {
                ...state,
                profile: {},
                error: null
            }
        case ACTION_TYPES.LOGOUT:
        case ACTION_TYPES.AUTHENTICATE_FAILED:
            axios.defaults.headers.common['Authorization'] = null;
            localStorage.removeItem("authTokenClefill");
            localStorage.removeItem("userClefill");
            localStorage.removeItem("FCMToken");
            return {
                ...state,
                isLoggedIn: false,
                message: '',
                userData: {}
            }
        case ACTION_TYPES.GET_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                notificationCount: action.payload.data?.count,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_NOTIFICATION_COUNT_ERROR:
            return {
                ...state,
                notificationCount: 0,
            }
        case ACTION_TYPES.GET_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                notificationList: action.payload.data ? action.payload.data : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_NOTIFICATION_LIST_ERROR:
            return {
                ...state,
                notificationList: [],
            }
        default:
            return state;
    }
}

export default loginReducer;